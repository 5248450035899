const Positions = {
  QB: 0,
  RB: 1,
  WR: 2,
  TE: 3,
  K: 4,
};

export const UNIVERSAL_VIG = 1.0623;

export const DataPoints = {
  AnyTD: 0,
  RushYds: 1,
  RecYds: 2,
  Recs: 3,
  PassTD: 4,
  PassYds: 5,
  Ints: 6,
};

export const slotcodes = {
  0: "QB", // Quarterback
  2: "RB", // Running Back
  4: "WR", // Wide Receiver
  16: "DST", // Defense/Special Teams
  6: "TE",
  23: "FLEX",
  17: "K",
  20: "BENCH",

  // Add more mappings as needed based on your specific data structure
};


//skipped amon-ra cuz he was commented out
export const PlayerPosMap = new Map([
  ["Tyrone Tracy (NYG)", Positions.RB],
  ["Jordan Whittington (LAR)", Positions.WR],
  ["Tyler Huntley (MIA)", Positions.QB],
  ["Trey Sermon (IND)", Positions.RB],
  ["Daniel Jones (NYG)", Positions.QB],
  ["Carson Steele (KC)", Positions.RB],
  ["Cam Akers (HOU)", Positions.RB],
  ["Sam Darnold (MIN)", Positions.QB],
  ["Zach Ertz (WAS)", Positions.TE],
  ["Bucky Irving (TB)", Positions.RB],
  ["JK Dobbins (LAC)", Positions.RB],
  ["Aaron Rodgers (NYJ)", Positions.QB],
  ["Kirk Cousins (ATL)", Positions.QB],
  ["Dawson Knox (BUF)", Positions.TE],
  ["Darren Waller (LV)", Positions.TE],
  ["Nick Mullens (MIN)", Positions.QB],
  ["Joshua Palmer (LAC)", Positions.WR],
  ["Joe Flacco (IND)", Positions.QB],
  ["Jake Browning (CIN)", Positions.QB],
  ["Desmond Ridder (ATL)", Positions.QB],
  ["Isaiah Likely (BAL)", Positions.TE],
  ["Tee Higgins (CIN)", Positions.WR],
  ["Dallas Goedert (PHI)", Positions.TE],
  ["Josh Allen (BUF)", Positions.QB],
  ["Lamar Jackson (BAL)", Positions.QB],
  ["Justin Herbert (LAC)", Positions.QB],
  ["Sam Howell (SEA)", Positions.QB],
  ["Geno Smith (SEA)", Positions.QB],
  ["Trevor Lawrence (JAX)", Positions.QB],
  ["Joe Burrow (CIN)", Positions.QB],
  ["Dak Prescott (DAL)", Positions.QB],
  ["Jared Goff (DET)", Positions.QB],
  ["Brock Purdy (SF)", Positions.QB],
  ["Baker Mayfield (TB)", Positions.QB],
  ["Kyler Murray (ARI)", Positions.QB],
  ["Jordan Love (GB)", Positions.QB],
  ["Christian McCaffrey (SF)", Positions.RB],
  ["Will Levis (TEN)", Positions.QB],
  ["CJ Stroud (HOU)", Positions.QB],
  ["Zach Wilson (NYJ)", Positions.QB],
  ["Taylor Heinicke (LAC)", Positions.QB],
  ["Mac Jones (JAX)", Positions.QB],
  ["Stefon Diggs (HOU)", Positions.WR],
  ["Gardner Minshew II (LV)", Positions.QB],
  ["Kenny Pickett (PHI)", Positions.QB],
  ["Austin Ekeler (WAS)", Positions.RB],
  ["Aidan O'Connell (LV)", Positions.QB],
  ["Keenan Allen (CHI)", Positions.WR],
  ["Tony Pollard (TEN)", Positions.RB],
  ["CeeDee Lamb (DAL)", Positions.WR],
  ["Jonathan Taylor (IND)", Positions.RB],
  ["Breece Hall (NYJ)", Positions.RB],
  ["Saquon Barkley (PHI)", Positions.RB],
  ["Rachaad White (TB)", Positions.RB],
  ["Aaron Jones", Positions.RB],
  ["Josh Jacobs (GB)", Positions.RB],
  ["Garrett Wilson (NYJ)", Positions.WR],
  ["Michael Pittman (IND)", Positions.WR],
  ["Rhamondre Stevenson (NE)", Positions.RB],
  ["Alvin Kamara (NO)", Positions.RB],
  ["Derrick Henry (BAL)", Positions.RB],
  ["Deshaun Watson (CLE)", Positions.QB],
  ["James Cook (BUF)", Positions.RB],
  ["Kenneth Walker (SEA)", Positions.RB],
  ["Terry McLaurin (WAS)", Positions.WR],
  ["Tyler Lockett (SEA)", Positions.WR],
  ["Brandon Aiyuk (SF)", Positions.WR],
  ["Diontae Johnson (CAR)", Positions.WR],
  ["DeAndre Hopkins (TEN)", Positions.WR],
  ["Mike Evans (TB)", Positions.WR],
  ["Travis Etienne Jr (JAX)", Positions.RB],
  ["Najee Harris (PIT)", Positions.RB],
  ["Chris Godwin (TB)", Positions.WR],
  ["Deebo Samuel (SF)", Positions.WR],
  ["Dalton Kincaid (BUF)", Positions.TE],
  ["Calvin Ridley (TEN)", Positions.WR],
  ["Jahmyr Gibbs (DET)", Positions.RB],
  ["David Montgomery (DET)", Positions.RB],
  ["Javonte Williams (DEN)", Positions.RB],
  ["Christian Kirk (JAX)", Positions.WR],
  ["Bijan Robinson (ATL)", Positions.RB],
  ["DK Metcalf (SEA)", Positions.WR],
  ["Jaylen Warren (PIT)", Positions.RB],
  ["Tommy DeVito (NYG)", Positions.QB],
  ["Evan Engram (JAX)", Positions.TE],
  ["Mark Andrews (BAL)", Positions.TE],
  ["Sam LaPorta (DET)", Positions.TE],
  ["Amari Cooper (CLE)", Positions.WR],
  ["Demario Douglas (NE)", Positions.WR],
  ["Jerome Ford (CLE)", Positions.RB],
  ["George Pickens (PIT)", Positions.WR],
  ["Zay Flowers (BAL)", Positions.WR],
  ["Jahan Dotson (PHI)", Positions.WR],
  ["George Kittle (SF)", Positions.TE],
  ["Davante Adams (LV)", Positions.WR],
  ["Jake Ferguson (DAL)", Positions.TE],
  ["Marquise Brown (KC)", Positions.WR],
  ["Jaxon Smith-Njigba (SEA)", Positions.WR],
  ["Gus Edwards (LAC)", Positions.RB],
  ["Khalil Shakir (BUF)", Positions.WR],
  ["Christian Watson (GB)", Positions.WR],
  ["Jerry Jeudy (CLE)", Positions.WR],
  ["Romeo Doubs (GB)", Positions.WR],
  ["Joe Mixon (HOU)", Positions.RB],
  ["Brandin Cooks (DAL)", Positions.WR],
  ["Logan Thomas (WAS)", Positions.TE],
  ["Tyjae Spears (TEN)", Positions.RB],
  ["Drake London (ATL)", Positions.WR],
  ["Courtland Sutton (DEN)", Positions.WR],
  ["Ezekiel Elliott (DAL)", Positions.RB],
  ["Trey McBride (ARI)", Positions.TE],
  ["Brian Robinson Jr (WAS)", Positions.RB],
  ["David Njoku (CLE)", Positions.TE],
  ["Hunter Henry (NE)", Positions.TE],
  ["Devin Singletary (NYG)", Positions.RB],
  ["Cade Otton (TB)", Positions.TE],
  ["Quentin Johnston (LAC)", Positions.WR],
  ["Jakobi Meyers (LV)", Positions.WR],
  ["Wan'Dale Robinson (NYG)", Positions.WR],
  ["Elijah Moore (CLE)", Positions.WR],
  ["Zach Charbonnet (SEA)", Positions.RB],
  ["Tank Dell (HOU)", Positions.WR],
  ["Gerald Everett (CHI)", Positions.TE],
  ["Allen Lazard (NYJ)", Positions.WR],
  ["Josh Reynolds (DEN)", Positions.WR],
  ["Kyle Pitts (ATL)", Positions.TE],
  ["Jayden Reed (GB)", Positions.WR],
  ["Michael Wilson (ARI)", Positions.WR],
  ["James Conner (ARI)", Positions.RB],
  ["Gabe Davis (JAX)", Positions.WR],
  ["Tyler Conklin (NYJ)", Positions.TE],
  ["Dalton Schultz (DAL)", Positions.TE],
  ["Zack Moss (CIN)", Positions.RB],
  ["Kareem Hunt (KC)", Positions.RB],
  ["Luke Musgrave (GB)", Positions.TE],
  ["Chig Okonkwo (TEN)", Positions.TE],
  ["Darius Slayton (NYG)", Positions.WR],
  ["Mike Gesicki (CIN)", Positions.TE],
  ["Connor Heyward (PIT)", Positions.TE],
  ["Taysom Hill (NO)", Positions.TE],
  ["Kyle Philips (PHI)", Positions.WR],
  ["Joshua Kelley (LAC)", Positions.RB],
  ["JuJu Smith-Schuster (KC)", Positions.WR],
  ["Odell Beckham Jr (MIA)", Positions.WR],
  ["Nick Westbrook-Ikhine (TEN)", Positions.WR],
  ["Noah Brown (WAS)", Positions.WR],
  ["Joshua Dobbs (SF)", Positions.QB],
  ["Jonnu Smith (MIA)", Positions.TE],
  ["Antonio Gibson (NE)", Positions.RB],
  ["AJ Dillon (GB)", Positions.RB],
  ["Tyler Allgeier (ATL)", Positions.RB],
  ["Trey Palmer (TB)", Positions.WR],
  ["Jameson Williams (DET)", Positions.WR],
  ["Rico Dowdle (DAL)", Positions.RB],
  ["Jauan Jennings (SF)", Positions.WR],
  ["Rashod Bateman (BAL)", Positions.WR],
  ["Jalen Reagor (NE)", Positions.WR],
  ["Keaton Mitchell (BAL)", Positions.RB],
  ["Robert Woods (HOU)", Positions.WR],
  ["Jalen Guyton (LAC)", Positions.WR],
  ["Noah Fant (SEA)", Positions.TE],
  ["Travis Etienne (JAX)", Positions.RB],
  ["Daniel Bellinger (NYG)", Positions.TE],
  ["Jalin Hyatt (NYG)", Positions.WR],
  ["Justin Jefferson (MIN)", Positions.WR],
  ["Amon-Ra St Brown (DET)", Positions.WR],
  ["Samaje Perine (KC)", Positions.RB],
  ["Donald Parham Jr (LAC)", Positions.TE],
  ["Brian Robinson (WAS)", Positions.RB],
  ["Dontayvion Wicks (GB)", Positions.WR],
  ["DK Metcalf (SEA)", Positions.WR],
  ["Alexander Mattison (LV)", Positions.RB],
  ["Gabriel Davis (BUF)", Positions.WR],
  ["Kalif Raymond (DET)", Positions.WR],
  ["Chris Olave (NO)", Positions.WR],
  ["Jordan Addison (MIN)", Positions.WR],
  ["KJ Osborn (MIN)", Positions.WR],
  ["TJ Hockenson (MIN)", Positions.TE],
  ["Michael Thomas (NO)", Positions.WR],
  ["Jaleel McLaughlin (IND)", Positions.RB],
  ["AJ Dillon (GB)", Positions.RB],
  ["Alec Pierce (IND)", Positions.WR],
  ["Josh Downs (IND)", Positions.WR],
  ["Michael Gallup (DAL)", Positions.WR],
  ["Latavius Murray (DEN)", Positions.RB],
  ["Donald Parham (DEN)", Positions.TE],
  ["Jamal Agnew (JAX)", Positions.WR],
  ["Tank Bigsby (JAX)", Positions.RB],
  ["Zay Jones (ARI)", Positions.WR],
  ["Allen Robinson (DET)", Positions.WR],
  ["Jamaal Williams (NO)", Positions.RB],
  ["Juwan Johnson (NO)", Positions.TE],
  ["Chris Moore (ARI)", Positions.WR],
  ["Curtis Samuel (BUF)", Positions.WR],
  ["Kylen Granson (IND)", Positions.TE],
  ["Justice Hill (BAL)", Positions.RB],
  ["Odell Beckham (MIA)", Positions.WR],
  ["Jake Bobo (SEA)", Positions.WR],
  ["Rashid Shaheed (NO)", Positions.WR],
  ["Elijah Mitchell (SF)", Positions.RB],
  ["Chigoziem Okonkwo (TEN)", Positions.WR],
  ["Marvin Mims (DEN)", Positions.WR],
  ["Brock Wright (DET)", Positions.TE],
  ["Gardner Minshew (LV)", Positions.QB],
  ["Brandon Powell (MIN)", Positions.WR],
  ["Ty Chandler (MIN)", Positions.RB],
  ["Chase Edmonds (TB)", Positions.RB],
  ["Donovan Peoples-Jones (DET)", Positions.WR],
  ["Xavier Gipson (NYJ)", Positions.WR],
  ["Michael Mayer (LV)", Positions.TE],
  ["Calvin Austin III (PIT)", Positions.WR],
  ["Jordan Mason (SF)", Positions.RB],
  ["Andrew Ogletree (IND)", Positions.TE],
  ["Isaiah McKenzie (NYG)", Positions.RB],
  ["Nelson Agholor (BAL)", Positions.WR],
  ["Foster Moreau (NO)", Positions.TE],
  ["Trishton Jackson (MIN)", Positions.WR],
  ["Antoine Green (DET)", Positions.WR],
  ["Craig Reynolds (DET)", Positions.RB],
  ["Devine Ozigbo (DEN)", Positions.RB],
  ["Jalen Tolbert (DAL)", Positions.WR],
  ["Kavontae Turpin (DAL)", Positions.RB],
  ["Colby Parkinson (LAR)", Positions.TE],
  ["DeeJay Dallas (ARI)", Positions.RB],

  ["Jamison Crowder (WAS)", Positions.WR],
  ["Deonte Harty (BAL)", Positions.WR],
  ["Quintin Morris (BUF)", Positions.TE],
  ["Adam Trautman (DEN)", Positions.TE],
  ["Russell Wilson (PIT)", Positions.QB],
  ["Harrison Bryant (LV)", Positions.WR],
  ["Tim Jones (JAX)", Positions.WR],
  ["Ray-Ray McCloud (ATL)", Positions.WR],
  ["Dalvin Cook (DAL)", Positions.RB],
  ["Michael Carter (ARI)", Positions.RB],
  ["Josh Oliver (MIN)", Positions.TE],
  ["Ja'Marr Chase (CIN)", Positions.WR],
  ["Tyler Boyd (TEN)", Positions.WR],
  ["Trenton Irwin (CIN)", Positions.WR],
  ["AJ Brown (PHI)", Positions.WR],
  ["DeVonta Smith (PHI)", Positions.WR],
  ["Rashee Rice (KC)", Positions.WR],
  ["Nico Collins (HOU)", Positions.WR],
  ["Mike Boone (CIN)", Positions.RB],
  ["Rondale Moore (ATL)", Positions.WR],
  ["John Metchie III (HOU)", Positions.WR],
  ["CJ Stroud (HOU)", Positions.QB],
  ["Keaontay Ingram (KC)", Positions.RB],
  ["Xavier Hutchinson (HOU)", Positions.WR],
  ["Brevin Jordan (HOU)", Positions.TE],
  ["Geoff Swaim (TEN)", Positions.TE],
  ["Greg Dortch (ARI)", Positions.WR],
  ["D'Onta Foreman (CLE)", Positions.RB],
  ["Justin Fields (PIT)", Positions.QB],
  ["Khalil Herbert (CHI)", Positions.RB],
  ["DJ Moore (CHI)", Positions.WR],
  ["Cole Kmet (CHI)", Positions.TE],
  ["Roschon Johnson (CHI)", Positions.RB],
  ["Darnell Mooney (ATL)", Positions.WR],
  ["Tyler Scott (CHI)", Positions.DE],
  ["Equanimeous St Brown (NO)", Positions.WR],
  ["Travis Homer (CHI)", Positions.RB],
  ["Robert Tonyan Jr (MIN)", Positions.TE],
  ["Marcedes Lewis (CHI)", Positions.TE],
  ["Trent Taylor (SF)", Positions.WR],
  ["Chuba Hubbard (CAR)", Positions.RB],
  ["Adam Thielen (CAR)", Positions.WR],
  ["Miles Sanders (CAR)", Positions.RB],
  ["DJ Chark (LAC)", Positions.WR],
  ["Jonathan Mingo (CAR)", Positions.WR],
  ["Terrace Marshall Jr (SF)", Positions.WR],
  ["Jalen Brooks (DAL)", Positions.WR],
  ["Bryce Young (CAR)", Positions.QB],
  ["Tommy Tremble (CAR)", Positions.TE],
  ["Luke Schoonmaker (DAL)", Positions.TE],
  ["Peyton Hendershot (DAL)", Positions.TE],
  ["Laviska Shenault Jr (SEA)", Positions.WR],
  ["Hunter Luepke (DAL)", Positions.FB],
  ["Sean McKeon (IND)", Positions.TE],
  ["Ian Thomas (CAR)", Positions.TE],
  ["DJ Chark Jr (LAC)", Positions.WR],
  ["Stephen Sullivan (CAR)", Positions.TE],
  ["Tyreek Hill (MIA)", Positions.WR],
  ["Raheem Mostert (MIA)", Positions.RB],
  ["De'Von Achane (MIA)", Positions.RB],
  ["Jaylen Waddle (MIA)", Positions.WR],
  ["Jeff Wilson Jr (MIA)", Positions.RB],
  ["Cedrick Wilson (NO)", Positions.WR],
  ["Durham Smythe (MIA)", Positions.TE],
  ["Salvon Ahmed (MIA)", Positions.RB],
  ["Tua Tagovailoa (MIA)", Positions.QB],
  ["Braxton Berrios (NYJ)", Positions.WR],
  ["Julian Hill (MIA)", Positions.WR],
  ["Austin Hooper (NE)", Positions.TE],
  ["Hunter Renfrow (LV)", Positions.WR],
  ["Alec Ingold (MIA)", Positions.FB],
  ["Ameer Abdullah (LV)", Positions.RB],
  ["Deandre Carter (CHI)", Positions.WR],
  ["Tre Tucker (LV)", Positions.WR],
  ["Zamir White (LV)", Positions.RB],
  ["Devon Achane (MIA)", Positions.RB],
  ["Derius Davis (LAC)", Positions.WR],
  ["Tucker Kraft (GB)", Positions.TE],
  ["Stone Smartt (LAC)", Positions.QB],
  ["Malik Heath (GB)", Positions.WR],
  ["Nick Vannett (TEN)", Positions.TE],
  ["Simi Fehoko (LAC)", Positions.WR],
  ["Josiah Deguara (JAX)", Positions.TE],
  ["Matt Breida (NYG)", Positions.RB],
  ["Chris Rodriguez Jr (WAS)", Positions.RB],
  ["Jashaun Corbin (CAR)", Positions.RB],
  ["Dyami Brown (WAS)", Positions.WR],
  ["Isaiah Hodgins (NYG)", Positions.WR],
  ["Byron Pringle (WAS)", Positions.WR],
  ["John Bates (WAS)", Positions.TE],
  ["Sterling Shepard (NYG)", Positions.WR],
  ["Deon Jackson (IND)", Positions.RB],
  ["Lawrence Cager (NYG)", Positions.WR],
  ["Parris Campbell (PHI)", Positions.WR],
  ["Pat Freiermuth (PIT)", Positions.TE],
  ["Dorian Thompson-Robinson (CLE)", Positions.QB],
  ["Cedric Tillman (CLE)", Positions.WR],
  ["David Bell (CLE)", Positions.WR],
  ["Pierre Strong (CLE)", Positions.RB],
  ["Miles Boykin (PIT)", Positions.WR],
  ["James Proche (CLE)", Positions.WR],
  ["Darnell Washington (PIT)", Positions.TE],
  ["Jordan Akins (CLE)", Positions.TE],
  ["Elijah Cooks (JAX)", Positions.WR],
  ["Brenton Strange (JAX)", Positions.TE],
  ["Luke Farrell (JAX)", Positions.TE],
  ["Parker Washington (JAX)", Positions.WR],
  ["Josh Whyle (TEN)", Positions.TE],
  ["Trevon Wesco (CHI)", Positions.TE],
  ["Kyle Juszczyk (SF)", Positions.FB],
  ["Deven Thompkins (CAR)", Positions.WR],
  ["Ronnie Bell (SF)", Positions.WR],
  ["Rakim Jarrett (TB)", Positions.WR],
  ["Ke'Shawn Vaughn (SF)", Positions.RB],
  ["Ko Kieft (TB)", Positions.TE],
  ["Payne Durham (TB)", Positions.TE],
  ["Israel Abanikanda (NYJ)", Positions.RB],
  ["Malik Taylor (NYJ)", Positions.WR],
  ["Randall Cobb (NYJ)", Positions.WR],
  ["Trent Sherfield (MIN)", Positions.WR],
  ["CJ Uzomah (NYJ)", Positions.TE],
  ["Reggie Gilliam (BUF)", Positions.FB],
  ["Irvin Charles (NYJ)", Positions.WR],
  ["Jeremy Ruckert (NYJ)", Positions.TE],
  ["Cooper Kupp (LAR)", Positions.WR],
  ["Darrell Henderson (JAX)", Positions.RB],
  ["Puka Nacua (LAR)", Positions.WR],
  ["Royce Freeman (LAR)", Positions.RB],
  ["Tutu Atwell (LAR)", Positions.WR],
  ["Tyler Higbee (LAR)", Positions.TE],
  ["Ben Skowronek (PIT)", Positions.WR],
  ["Matthew Stafford (LAR)", Positions.QB],
  ["Will Dissly (LAC)", Positions.TE],
  ["Demarcus Robinson (LAR)", Positions.WR],
  ["Brycen Hopkins (LAR)", Positions.TE],
  ["Davis Allen (LAR)", Positions.TE],
  ["Darrell Henderson Jr (JAX)", Positions.RB],
  ["Kene Nwangwu (MIN)", Positions.RB],
  ["Lil'Jordan Humphrey (DEN)", Positions.WR],
  ["Myles Gaskin (MIN)", Positions.RB],
  ["Jalen Nailor (MIN)", Positions.WR],
  ["Chris Manhertz (NYG)", Positions.TE],
  ["N'Keal Harry (MIN)", Positions.WR],
  ["Johnny Mundt (MIN)", Positions.TE],
  ["KJ Osborn (NE)", Positions.WR],
  ["Marvin Mims Jr (DEN)", Positions.WR],
  ["Travis Kelce (KC)", Positions.TE],
  ["Isiah Pacheco (KC)", Positions.RB],
  ["Jalen Hurts (PHI)", Positions.QB],
  ["Patrick Mahomes (KC)", Positions.QB],
  ["D'Andre Swift (CHI)", Positions.RB],
  ["Jerick McKinnon (KC)", Positions.RB],
  ["Justin Watson (KC)", Positions.WR],
  ["Kadarius Toney (KC)", Positions.WR],
  ["Marquez Valdes-Scantling (BUF)", Positions.WR],
  ["Skyy Moore (KC)", Positions.WR],
  ["Clyde Edwards-Helaire (KC)", Positions.RB],
  ["Mecole Hardman (NYJ)", Positions.WR],
  ["Noah Gray (KC)", Positions.TE],
  ["Kyren Williams (LAR)", Positions.RB],
  ["Gardner Minshew (LV)", Positions.QB],
  ["Derek Carr (NO)", Positions.QB],

  ["Marvin Harrison (ARI)", Positions.WR],
  ["Malik Nabers (NYG)", Positions.WR],
  ["Jonathon Brooks (CAR)", Positions.RB],
  ["Brock Bowers (LV)", Positions.TE],
  ["Brian Thomas (JAX)", Positions.WR],
  ["Xavier Worthy (KC)", Positions.WR],
  ["Xavier Legette (CAR)", Positions.WR],
  ["Caleb Williams (CHI)", Positions.QB],
  ["Jayden Daniels (WAS)", Positions.QB],
  ["Ladd McConkey (LAC)", Positions.WR],
  ["Keon Coleman (BUF)", Positions.WR],
  ["Ricky Pearsall (SF)", Positions.WR],
  ["Adonai Mitchell (IND)", Positions.WR],
  ["Trey Benson (ARI)", Positions.RB],
  ["Drake Maye (NE)", Positions.QB],
  ["Ja'Lynn Polk (NE)", Positions.WR],
  ["Jaylen Wright (MIA)", Positions.RB],
  ["MarShawn Lloyd (GB)", Positions.RB],
  ["Jared Wiley (KC)", Positions.TE],
  ["Brenden Rice (LAC)", Positions.WR],
  ["Ainias Smith (PHI)", Positions.WR],
  ["Isaiah Davis (NYJ)", Positions.RB],
  ["Rasheen Ali (BAL)", Positions.RB],
  ["Kimani Vidal (LAC)", Positions.RB],
  ["Johnny Wilson (PHI)", Positions.WR],
  ["Jha'Quan Jackson (TEN)", Positions.WR],
  ["Jawhar Jordan (HOU)", Positions.RB],
  ["Keilan Robinson (JAX)", Positions.RB],
  ["Tre Tucker (LV)", Positions.WR],
  ["Ben Sinnott (WAS)", Positions.TE],
  ["Jalen McMillan (TB)", Positions.WR],
  ["Luke McCaffrey (WAS)", Positions.WR],
  ["Bo Nix (DEN)", Positions.QB],
  ["Blake Corum (LAR)", Positions.RB],
  ["Rome Odunze (CHI)", Positions.WR],
  ["Anthony Richardson (IND)", Positions.QB],

  // need to add chiefs and dolphins players / week 10 byes
]);

function removeAfterLastSpaceFromMapKeys(originalMap) {
  const newMap = new Map();

  for (let [key, value] of originalMap.entries()) {
      // Find the index of the last space in the key
      const lastSpaceIndex = key.lastIndexOf(' ');

      // If a space was found, remove everything from the last space onward
      const newKey = lastSpaceIndex !== -1 ? key.slice(0, lastSpaceIndex) : key;

      newMap.set(newKey, value);
  }

  return newMap;
}

export const PlayerPosMapNoPos = removeAfterLastSpaceFromMapKeys(PlayerPosMap);

export const PlayerPosMap23 = new Map([
  ["Kirk Cousins", Positions.QB],
  ["Dawson Knox", Positions.TE],
  ["Darren Waller", Positions.TE],
  ["Nick Mullens", Positions.QB],
  ["Joshua Palmer", Positions.WR],
  ["Joe Flacco", Positions.QB],
  ["Jake Browning", Positions.QB],
  ["Desmond Ridder", Positions.QB],
  ["Isaiah Likely", Positions.TE],
  ["Tee Higgins", Positions.WR],
  ["Dallas Goedert", Positions.TE],
  ["Josh Allen", Positions.QB],
  ["Lamar Jackson", Positions.QB],
  ["Justin Herbert", Positions.QB],
  ["Sam Howell", Positions.QB],
  ["Geno Smith", Positions.QB],
  ["Trevor Lawrence", Positions.QB],
  ["Joe Burrow", Positions.QB],
  ["Dak Prescott", Positions.QB],
  ["Jared Goff", Positions.QB],
  ["Brock Purdy", Positions.QB],
  ["Baker Mayfield", Positions.QB],
  ["Kyler Murray", Positions.QB],
  ["Jordan Love", Positions.QB],
  ["Christian McCaffrey", Positions.RB],
  ["Will Levis", Positions.QB],
  ["CJ Stroud", Positions.QB],
  ["Zach Wilson", Positions.QB],
  ["Taylor Heinicke", Positions.QB],
  ["Mac Jones", Positions.QB],
  ["Stefon Diggs", Positions.WR],
  ["Gardner Minshew II", Positions.QB],
  ["Kenny Pickett", Positions.QB],
  ["Austin Ekeler", Positions.RB],
  ["Aidan O'Connell", Positions.QB],
  ["Keenan Allen", Positions.WR],
  ["Tony Pollard", Positions.RB],
  ["CeeDee Lamb", Positions.WR],
  ["Jonathan Taylor", Positions.RB],
  ["Breece Hall", Positions.RB],
  ["Saquon Barkley", Positions.RB],
  ["Rachaad White", Positions.RB],
  ["Aaron Jones", Positions.RB],
  // ["Amon-Ra St Brown", Positions.WR],
  ["Josh Jacobs", Positions.RB],
  ["Garrett Wilson", Positions.WR],
  ["Michael Pittman", Positions.WR],
  ["Rhamondre Stevenson", Positions.RB],
  ["Alvin Kamara", Positions.RB],
  ["Derrick Henry", Positions.RB],
  ["Deshaun Watson", Positions.QB],
  ["James Cook", Positions.RB],
  ["Kenneth Walker", Positions.RB],
  ["Terry McLaurin", Positions.WR],
  ["Tyler Lockett", Positions.WR],
  ["Brandon Aiyuk", Positions.WR],
  ["Diontae Johnson", Positions.WR],
  ["DeAndre Hopkins", Positions.WR],
  ["Mike Evans", Positions.WR],
  ["Travis Etienne Jr", Positions.RB],
  ["Najee Harris", Positions.RB],
  ["Chris Godwin", Positions.WR],
  ["Deebo Samuel", Positions.WR],
  ["Dalton Kincaid", Positions.TE],
  ["Calvin Ridley", Positions.WR],
  ["Jahmyr Gibbs", Positions.RB],
  ["David Montgomery", Positions.RB],
  ["Javonte Williams", Positions.RB],
  ["Christian Kirk", Positions.WR],
  ["Bijan Robinson", Positions.RB],
  ["DK Metcalf", Positions.WR],
  ["Jaylen Warren", Positions.RB],
  ["Tommy DeVito", Positions.QB],
  ["Evan Engram", Positions.TE],
  ["Mark Andrews", Positions.TE],
  ["Sam LaPorta", Positions.TE],
  ["Amari Cooper", Positions.WR],
  ["Demario Douglas", Positions.WR],
  ["Jerome Ford", Positions.RB],
  ["George Pickens", Positions.WR],
  ["Zay Flowers", Positions.WR],
  ["Jahan Dotson", Positions.WR],
  ["George Kittle ", Positions.TE],
  ["Davante Adams", Positions.WR],
  ["Jake Ferguson", Positions.TE],
  ["Marquise Brown", Positions.WR],
  ["Jaxon Smith-Njigba", Positions.WR],
  ["Gus Edwards", Positions.RB],
  ["Khalil Shakir", Positions.WR],
  ["Christian Watson", Positions.WR],
  ["Jerry Jeudy", Positions.WR],
  ["Romeo Doubs", Positions.WR],
  ["Joe Mixon", Positions.RB],
  ["Brandin Cooks", Positions.WR],
  ["Logan Thomas", Positions.TE],
  ["Tyjae Spears", Positions.RB],
  ["Drake London", Positions.WR],
  ["Courtland Sutton", Positions.WR],
  ["Ezekiel Elliott", Positions.RB],
  ["Trey McBride", Positions.TE],
  ["Brian Robinson Jr", Positions.RB],
  ["David Njoku", Positions.TE],
  ["Hunter Henry", Positions.TE],
  ["Devin Singletary", Positions.RB],
  ["Cade Otton", Positions.TE],
  ["Quentin Johnston", Positions.WR],
  ["Jakobi Meyers", Positions.WR],
  ["Wan'Dale Robinson", Positions.WR],
  ["Elijah Moore", Positions.WR],
  ["Zach Charbonnet", Positions.RB],
  ["Tank Dell", Positions.WR],
  ["Gerald Everett", Positions.TE],
  ["Allen Lazard", Positions.WR],
  ["Josh Reynolds", Positions.WR],
  ["Kyle Pitts", Positions.TE],
  ["Jayden Reed", Positions.WR],
  ["Michael Wilson", Positions.WR],
  ["James Conner", Positions.RB],
  ["Gabe Davis", Positions.WR],
  ["Tyler Conklin", Positions.TE],
  ["Dalton Schultz", Positions.TE],
  ["Zack Moss", Positions.RB],
  ["Kareem Hunt", Positions.RB],
  ["Luke Musgrave", Positions.TE],
  ["Chig Okonkwo", Positions.TE],
  ["Darius Slayton", Positions.WR],
  ["Mike Gesicki", Positions.TE],
  ["Connor Heyward", Positions.TE],
  ["Taysom Hill", Positions.TE],
  ["Kyle Philips", Positions.WR],
  ["Joshua Kelley", Positions.RB],
  ["JuJu Smith-Schuster", Positions.WR],
  ["Odell Beckham Jr", Positions.WR],
  ["Nick Westbrook-Ikhine", Positions.WR],
  ["Noah Brown", Positions.WR],
  ["Joshua Dobbs", Positions.QB],
  ["Jonnu Smith", Positions.TE],
  ["Antonio Gibson", Positions.RB],
  ["AJ Dillon", Positions.RB],
  ["Tyler Allgeier", Positions.RB],
  ["Trey Palmer", Positions.WR],
  ["Jameson Williams", Positions.WR],
  ["Rico Dowdle", Positions.RB],
  ["Jauan Jennings", Positions.WR],
  ["Rashod Bateman", Positions.WR],
  ["Jalen Reagor", Positions.WR],
  ["Keaton Mitchell", Positions.RB],
  ["Robert Woods", Positions.WR],
  ["Jalen Guyton", Positions.WR],
  ["Noah Fant", Positions.TE],
  ["Travis Etienne", Positions.RB],
  ["Daniel Bellinger", Positions.TE],
  ["Jalin Hyatt", Positions.WR],
  ["Justin Jefferson", Positions.WR],
  ["Amon-Ra St Brown", Positions.WR],
  ["Samaje Perine", Positions.RB],
  ["Donald Parham Jr", Positions.TE],
  ["Brian Robinson", Positions.RB],
  ["Dontayvion Wicks", Positions.WR],
  ["DK Metcalf", Positions.WR],
  ["Alexander Mattison", Positions.RB],
  ["Gabriel Davis", Positions.WR],
  ["Kalif Raymond", Positions.WR],
  ["Chris Olave", Positions.WR],
  ["Jordan Addison", Positions.WR],
  ["KJ Osborn", Positions.WR],
  ["TJ Hockenson", Positions.TE],
  ["Michael Thomas", Positions.WR],
  ["Jaleel McLaughlin", Positions.RB],
  ["AJ Dillon", Positions.RB],
  ["Alec Pierce", Positions.WR],
  ["Josh Downs", Positions.WR],
  ["Michael Gallup", Positions.WR],
  ["Latavius Murray", Positions.RB],
  ["Donald Parham", Positions.TE],
  ["Jamal Agnew", Positions.WR],
  ["Tank Bigsby", Positions.RB],
  ["Zay Jones", Positions.WR],
  ["Allen Robinson", Positions.WR],
  ["Jamaal Williams", Positions.RB],
  ["Juwan Johnson", Positions.TE],
  ["Chris Moore", Positions.WR],
  ["Curtis Samuel", Positions.WR],
  ["Kylen Granson", Positions.TE],
  ["Justice Hill", Positions.RB],
  ["Odell Beckham", Positions.WR],
  ["Jake Bobo", Positions.WR],
  ["Rashid Shaheed", Positions.WR],
  ["Elijah Mitchell", Positions.RB],
  ["Chigoziem Okonkwo", Positions.WR],
  ["Marvin Mims", Positions.WR],
  ["Brock Wright", Positions.TE],
  ["Gardner Minshew", Positions.QB],
  ["Brandon Powell", Positions.WR],
  ["Ty Chandler", Positions.RB],
  ["Chase Edmonds", Positions.RB],
  ["Donovan Peoples-Jones", Positions.WR],
  ["Xavier Gipson", Positions.WR],
  ["Michael Mayer", Positions.TE],
  ["Calvin Austin III", Positions.WR],
  ["Jordan Mason", Positions.RB],
  ["Andrew Ogletree", Positions.TE],
  ["Isaiah McKenzie", Positions.RB],
  ["Nelson Agholor", Positions.WR],
  ["Foster Moreau", Positions.TE],
  ["Trishton Jackson", Positions.WR],
  ["Antoine Green", Positions.WR],
  ["Craig Reynolds", Positions.RB],
  ["Devine Ozigbo", Positions.RB],
  ["Jalen Tolbert", Positions.WR],
  ["Kavontae Turpin", Positions.RB],
  ["Colby Parkinson", Positions.TE],
  ["DeeJay Dallas", Positions.RB],
  ["Jamison Crowder", Positions.WR],
  ["Deonte Harty", Positions.WR],
  ["Quintin Morris", Positions.TE],
  ["Adam Trautman", Positions.TE],
  ["Russell Wilson", Positions.QB],
  ["Harrison Bryant", Positions.WR],
  ["Tim Jones", Positions.WR],
  ["Ray-Ray McCloud", Positions.WR],
  ["Dalvin Cook", Positions.RB],
  ["Michael Carter", Positions.RB],
  ["Josh Oliver", Positions.TE],
  ["Ja'Marr Chase", Positions.WR],
  ["Tyler Boyd", Positions.WR],
  ["Trenton Irwin", Positions.WR],
  ["AJ Brown", Positions.WR],
  ["DeVonta Smith", Positions.WR],
  ["Rashee Rice", Positions.WR],
  ["Nico Collins", Positions.WR],
  ["Mike Boone", Positions.RB],
  ["Rondale Moore", Positions.WR],
  ["John Metchie III", Positions.WR],
  ["CJ Stroud", Positions.QB],
  ["Keaontay Ingram", Positions.RB],
  ["Xavier Hutchinson", Positions.WR],
  ["Brevin Jordan", Positions.TE],
  ["Geoff Swaim", Positions.TE],
  ["Greg Dortch", Positions.WR],
  ["D'Onta Foreman", Positions.RB],
  ["Justin Fields", Positions.QB],
  ["Khalil Herbert", Positions.RB],
  ["DJ Moore", Positions.WR],
  ["Cole Kmet", Positions.TE],
  ["Roschon Johnson", Positions.RB],
  ["Darnell Mooney", Positions.WR],
  ["Tyler Scott", Positions.DE],
  ["Equanimeous St Brown", Positions.WR],
  ["Travis Homer", Positions.RB],
  ["Robert Tonyan Jr", Positions.TE],
  ["Marcedes Lewis", Positions.TE],
  ["Trent Taylor", Positions.WR],
  ["Chuba Hubbard", Positions.RB],
  ["Adam Thielen", Positions.WR],
  ["Miles Sanders", Positions.RB],
  ["DJ Chark", Positions.WR],
  ["Jonathan Mingo", Positions.WR],
  ["Terrace Marshall Jr", Positions.WR],
  ["Jalen Brooks", Positions.WR],
  ["Bryce Young", Positions.QB],
  ["Tommy Tremble", Positions.TE],
  ["Luke Schoonmaker", Positions.TE],
  ["Peyton Hendershot", Positions.TE],
  ["Laviska Shenault Jr", Positions.WR],
  ["Hunter Luepke", Positions.FB],
  ["Sean McKeon", Positions.TE],
  ["Ian Thomas", Positions.TE],
  ["DJ Chark Jr", Positions.WR],
  ["Stephen Sullivan", Positions.TE],
  ["Tyreek Hill", Positions.WR],
  ["Raheem Mostert", Positions.RB],
  ["De'Von Achane", Positions.RB],
  ["Jaylen Waddle", Positions.WR],
  ["Jeff Wilson Jr", Positions.RB],
  ["Cedrick Wilson", Positions.WR],
  ["Durham Smythe", Positions.TE],
  ["Salvon Ahmed", Positions.RB],
  ["Tua Tagovailoa", Positions.QB],
  ["Braxton Berrios", Positions.WR],
  ["Julian Hill", Positions.WR],
  ["Austin Hooper", Positions.TE],
  ["Hunter Renfrow", Positions.WR],
  ["Alec Ingold", Positions.FB],
  ["Ameer Abdullah", Positions.RB],
  ["Deandre Carter", Positions.WR],
  ["Tre Tucker", Positions.WR],
  ["Zamir White", Positions.RB],
  ["Devon Achane", Positions.RB],
  ["Derius Davis", Positions.WR],
  ["Tucker Kraft", Positions.TE],
  ["Stone Smartt", Positions.QB],
  ["Malik Heath", Positions.WR],
  ["Nick Vannett", Positions.TE],
  ["Simi Fehoko", Positions.WR],
  ["Josiah Deguara", Positions.TE],
  ["Matt Breida", Positions.RB],
  ["Chris Rodriguez Jr", Positions.RB],
  ["Jashaun Corbin", Positions.RB],
  ["Dyami Brown", Positions.WR],
  ["Isaiah Hodgins", Positions.WR],
  ["Byron Pringle", Positions.WR],
  ["John Bates", Positions.TE],
  ["Sterling Shepard", Positions.WR],
  ["Deon Jackson", Positions.RB],
  ["Lawrence Cager", Positions.WR],
  ["Parris Campbell", Positions.WR],
  ["Pat Freiermuth", Positions.TE],
  ["Dorian Thompson-Robinson", Positions.QB],
  ["Cedric Tillman", Positions.WR],
  ["David Bell", Positions.WR],
  ["Pierre Strong", Positions.RB],
  ["Miles Boykin", Positions.WR],
  ["James Proche", Positions.WR],
  ["Darnell Washington", Positions.TE],
  ["Jordan Akins", Positions.TE],
  ["Elijah Cooks", Positions.WR],
  ["Brenton Strange", Positions.TE],
  ["Luke Farrell", Positions.TE],
  ["Parker Washington", Positions.WR],
  ["Josh Whyle", Positions.TE],
  ["Trevon Wesco", Positions.TE],
  ["Kyle Juszczyk", Positions.FB],
  ["Deven Thompkins", Positions.WR],
  ["Ronnie Bell", Positions.WR],
  ["Rakim Jarrett", Positions.WR],
  ["Ke'Shawn Vaughn", Positions.RB],
  ["Ko Kieft", Positions.TE],
  ["Payne Durham", Positions.TE],
  ["Israel Abanikanda", Positions.RB],
  ["Malik Taylor", Positions.WR],
  ["Randall Cobb", Positions.WR],
  ["Trent Sherfield", Positions.WR],
  ["CJ Uzomah", Positions.TE],
  ["Reggie Gilliam", Positions.FB],
  ["Irvin Charles", Positions.WR],
  ["Jeremy Ruckert", Positions.TE],
  ["Cooper Kupp", Positions.WR],
  ["Darrell Henderson", Positions.RB],
  ["Puka Nacua", Positions.WR],
  ["Royce Freeman", Positions.RB],
  ["Tutu Atwell", Positions.WR],
  ["Tyler Higbee", Positions.TE],
  ["Ben Skowronek", Positions.WR],
  ["Matthew Stafford", Positions.QB],
  ["Will Dissly", Positions.TE],
  ["Demarcus Robinson", Positions.WR],
  ["Brycen Hopkins", Positions.TE],
  ["Davis Allen", Positions.TE],
  ["Darrell Henderson Jr", Positions.RB],
  ["Kene Nwangwu", Positions.RB],
  ["Lil'Jordan Humphrey", Positions.WR],
  ["Myles Gaskin", Positions.RB],
  ["Jalen Nailor", Positions.WR],
  ["Chris Manhertz", Positions.TE],
  ["N'Keal Harry", Positions.WR],
  ["Johnny Mundt", Positions.TE],
  ["KJ Osborn", Positions.WR],
  ["Marvin Mims Jr", Positions.WR],
  ["Travis Kelce", Positions.TE],
  ["Isiah Pacheco", Positions.RB],
  ["Jalen Hurts", Positions.QB],
  ["Patrick Mahomes", Positions.QB],
  ["D'Andre Swift", Positions.RB],
  ["Jerick McKinnon", Positions.RB],
  ["Justin Watson", Positions.WR],
  ["Kadarius Toney", Positions.WR],
  ["Marquez Valdes-Scantling", Positions.WR],
  ["Skyy Moore", Positions.WR],
  ["Clyde Edwards-Helaire", Positions.RB],
  ["Mecole Hardman", Positions.WR],
  ["Noah Gray", Positions.TE],
  ["Kyren Williams", Positions.RB],
  ["Gardner Minshew", Positions.QB],
  ["Derek Carr", Positions.QB],
  ["Anthony Richardson (IND)", Positions.QB],

  // need to add chiefs and dolphins players / week 10 byes
]);
